import Container from "../../shared/components/Container";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "../../shared/components/ui/breadcrumb";
import CardObjetoEducacional from "../../shared/components/oe-card";
import { ObjetoEducacional, Root } from "../../models/ObjetoEducacional";
import { fetchApi } from "../../shared/services/rest-dotnet.service";
import { useEffect, useState } from "react";
import Menu from "../../shared/components/menu";
import { useLocation, useParams } from "react-router";
import { useScrollTop } from "../../shared/hooks/useScrollTop";
import Pagination from "@mui/material/Pagination";
import React from "react";

interface SearchProps {
  q?: string;
  a?: string;
  p?: string;
  m?: string;
  n?: string;
  t?: string;
  s?: string;
  c?: string;
  page?: number;
}

async function searchObjetosEducacionais({
  q,
  a,
  p,
  m,
  n,
  t,
  s,
  c,
  page,
}: SearchProps): Promise<Root> {
  const params = {
    titulo: q || "",
    areaDeInteresses: Array.isArray(a) ? a : a ? [a] : [],
    profissoes: Array.isArray(p) ? p : p ? [p] : [],
    metodosDeEnsino: Array.isArray(m) ? m : m ? [m] : [],
    niveisMaturidade: Array.isArray(n) ? n : n ? [n] : [],
    tipoMidia: Array.isArray(t) ? t : t ? [t] : [],
    possuiCertificado: c === "true",
    ordernar: s || "1",
    pageIndex: page || 1,
    pageSize: 15,
  };

  const response = await fetchApi(
    "busca/get-objeto-educacional",
    "POST",
    JSON.stringify(params),
  ).then((res) => {
    if (res) {
      return res.json();
    } else {
      return {
        items: [],
        totalItems: 0,
        pageIndex: 0,
        pageSize: 0,
        totalPages: 0,
      } as Root;
    }
  });

  return response;
}

export default function ObjetoEducacionalSearch() {
  const [objetos, setObjetos] = useState<Root>();
  const [searchParams, setSearchParams] = useState<SearchProps>(
    {} as SearchProps,
  );
  const [page, setPage] = useState<number>(1);
  const scrollTop = useScrollTop();

  const location = useLocation();

  const loadObjetos = async (search: SearchProps) => {
    setObjetos(await searchObjetosEducacionais(search || searchParams));
  };

  useEffect(() => {
    const queryLocation = new URLSearchParams(location.search);
    const search = {
      q: queryLocation.get("q") || undefined,
      a: queryLocation.get("a") || undefined,
      m: queryLocation.get("m") || undefined,
      n: queryLocation.get("n") || undefined,
      p: queryLocation.get("p") || undefined,
      t: queryLocation.get("t") || undefined,
      s: queryLocation.get("s") || undefined,
      c: queryLocation.get("c") || undefined,
      page: page,
    };
    setSearchParams(search);
    loadObjetos(search);
  }, [location.search, page]);

  scrollTop();

  return (
    <>
      <div
        style={{
          background: "linear-gradient(137.1deg, #107B95 6.6%, #1BB28E 98.07%)",
        }}
        className="flex h-32 w-full"
      >
        <Container className="!px-0 py-9">
          <Breadcrumb className="flex justify-start !text-xxs font-normal text-white-200 md:text-xs">
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Home</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink href="/novos-conteudos-interesse">
                  Resultado da Busca
                </BreadcrumbLink>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <h2 className="leading-6.5 mt-3 text-base font-semibold normal-case text-white-200 md:text-1.5xl">
            {objetos?.totalItems && objetos?.totalItems > 0
              ? `Resultados para "${searchParams.q ?? ""}"`
              : `Nenhum resultado encontrado para "${searchParams.q || ""}"`}
          </h2>
        </Container>
      </div>
      <Container className="h-full min-h-screen  py-9 pb-9">
        <div className="flex flex-col gap-10">
          <Menu qtdConteudos={objetos?.totalItems} />

          <div className="mt-5 grid grid-cols-1 gap-6 md:mt-0 md:grid-cols-3">
            {objetos?.items?.map((_: ObjetoEducacional, index: number) => (
              <div key={index} className="p-1">
                <CardObjetoEducacional objetoEducacional={_} />
              </div>
            ))}
          </div>
          {objetos && objetos?.totalPages > 1 && (
            <div className="flex w-full">
              <Pagination
                count={objetos?.totalPages}
                onChange={(e, p) => setPage(p)}
                variant="outlined"
                color="primary"
              />
            </div>
          )}
        </div>
      </Container>
    </>
  );
}
