import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Container from "../../shared/components/Container";
import { Button } from "../../shared/components/ui/button";
import Rotulo from "../../shared/components/rotulos";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../shared/components/ui/card";
import { BreadcrumbItem, Loader, Pagination } from "../../shared/components";
import Menu from "../../shared/components/menu";
import fetchApi from "../../shared/services/rest-dotnet.service";
import {
  Breadcrumb,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "../../shared/components/ui/breadcrumb";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";
import { mostrarModalLogin } from "../../shared/modals/ModalLogin";
import { UserContext } from "../../context/UserContext";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

async function getEventos() {
  const response = await fetchApi("evento/get-eventos", "GET").then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return [];
    }
  });

  return response;
}

export default function Eventos() {
  const [page, setPage] = useState<number>(1);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [eventos, setEventos] = useState<any[]>([]);
  const gtm = useGtm();
  const { user, loadingUser } = useContext(UserContext);
  const history = useHistory();

  const query = useQuery();
  const searchQuery = query.get("q");
  const sortOrder = query.get("s");

  const loadAssistidos = async () => {
    setEventos(await getEventos());
  };

  useEffect(() => {
    loadAssistidos();
  }, []);

  useEffect(() => {
    if (user === undefined && !loadingUser) {
      mostrarModalLogin(true, () => {
        history.push("/eventos");
      });
    }
  }, [user, loadingUser]);

  useEffect(() => {
    let itemsFiltered = eventos;

    if (searchQuery) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      itemsFiltered = itemsFiltered.filter((item: any) =>
        item.nome.toLowerCase().includes(lowerCaseQuery),
      );
    }

    if (sortOrder) {
      const lowerCaseSortOrder = sortOrder.toLowerCase();
      if (lowerCaseSortOrder === "3") {
        itemsFiltered = itemsFiltered.sort((a: any, b: any) =>
          a.nome.localeCompare(b.nome),
        );
      } else if (lowerCaseSortOrder === "4") {
        itemsFiltered = itemsFiltered.sort((a: any, b: any) =>
          b.nome.localeCompare(a.nome),
        );
      }
    }

    setFilteredItems(itemsFiltered);
  }, [searchQuery, sortOrder, eventos]);

  return (
    <>
      <div
        style={{
          background: "linear-gradient(137.1deg, #107B95 6.6%, #1BB28E 98.07%)",
        }}
        className="flex h-32 w-full"
      >
        <Container className="py-10 ">
          <Breadcrumb className="text-start  font-normal text-white-200">
            <BreadcrumbList className="flex justify-start text-start">
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Home</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink href="/eventos">
                  Eventos Científicos
                </BreadcrumbLink>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <h2 className="leading-6.5 mt-3 text-1.5xl font-semibold normal-case text-white-200">
            Eventos Científicos
          </h2>
        </Container>
      </div>
      <Container className="h-full min-h-screen py-9 pb-9">
        <Loader show={false} />

        <Menu qtdConteudos={filteredItems?.length || 0} typeFiltro="EVENTOS" />

        <div className="mt-5 flex flex-col gap-10 md:mt-0">
          <div className="mt-5 grid grid-cols-1 gap-6 md:mt-0 md:grid-cols-4">
            {filteredItems
              .slice((page - 1) * 15, page * 15)
              .map((item: any, index: number) => (
                <Card
                  key={index}
                  className="flex min-h-[300px]  flex-col overflow-hidden border md:min-h-[420px]"
                >
                  <CardHeader className="relative mt-[-5px] flex h-[150px] p-0">
                    <img
                      src={item.imagemUrl}
                      alt={item.nome}
                      className="not-sr-only w-full bg-bottom bg-no-repeat object-cover"
                    />
                    <div className="absolute left-2 top-2">
                      <Rotulo
                        rotuloName="EVENTOS"
                        key={index}
                        id={item.idProduto}
                        isMetodoEnsino={true}
                      />
                    </div>
                  </CardHeader>
                  <CardContent className="mt-2 flex h-full flex-col gap-3">
                    <h3 className="line-clamp-3 !text-base font-semibold text-[#111316]">
                      {item.nome}
                    </h3>
                    <p className="text-sm font-medium leading-4 text-[#004F92]">
                      {item.dataEvento}
                    </p>
                    <p className=" text-sm font-normal leading-4">
                      {item.localEvento}
                    </p>
                    <p className=" text-sm font-normal leading-4">
                      {item.cidade === "São Paulo"
                        ? "São Paulo - SP"
                        : item.cidade}
                    </p>
                  </CardContent>
                  <CardFooter className="flex justify-center">
                    <Button
                      variant={"primary"}
                      data-testid="button-conheca"
                      className="w-[134px]"
                      onClick={() => {
                        window.open(item.eventoUrl, "_blank");
                        gtm.push(
                          EDataLayerEventCategory.ADE_EVENTOS_CIENTIFICOS,
                          EDataLayerEventAction.CLIQUE,
                          {
                            label: `eventos_cientificos_${item.id}`,
                          },
                        );
                      }}
                    >
                      Conheça
                    </Button>
                  </CardFooter>
                </Card>
              ))}
          </div>

          <div className="flex w-full justify-end">
            <Pagination
              count={Math.ceil(filteredItems.length / 15)}
              page={page}
              onChange={(event: any, value: any) => {
                setPage(value);
              }}
            />
          </div>
        </div>
      </Container>
    </>
  );
}
