import Container from "../../../shared/components/Container";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "../../../shared/components/ui/breadcrumb";

import Menu from "../../../shared/components/menu";
import CapaPlaylist from "../../../assets/images/capa-playlist.png";
import Rotulo from "../../../shared/components/rotulos";
import { Favoritar, Loader } from "../../../shared/components";
import { Badge } from "../../../shared/components/ui/badge";
import {
  Card,
  CardContent,
  CardHeader,
} from "../../../shared/components/ui/card";
import { useEffect, useState } from "react";
import { fetchApi } from "../../../shared/services/rest-dotnet.service";
import { useHistory, useLocation, useParams } from "react-router";
import { useScrollTop } from "../../../shared/hooks/useScrollTop";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../shared/components/ui/dropdown";
import { EllipsisVertical, Bookmark, Send } from "lucide-react";
import { useModalPlaylist } from "../../../hooks/useModalPlaylist";

export interface Playlist {
  id: number;
  imagem?: string;
  titulo: string;
  thumbnail: string;
  qtdVideos: number;
  categoriaDescricao: string;
  visualizado: boolean;
}

interface PlaylistResponse {
  items: Playlist[];
  totalItems: number;
  pageIndex: number;
  pageSize: number;
  totalPages: number;
}

interface SearchProps {
  categoriaId?: string;
  titulo?: string;
  sort?: string;
  page?: string;
}

async function getCategoriasPlaylists({
  categoriaId,
  sort,
  titulo,
  page,
}: SearchProps) {
  const response = await fetchApi(
    "playlists/playlists",
    "POST",
    JSON.stringify({
      titulo: titulo || "",
      categoriaId: categoriaId,
      pageIndex: page,
      pageSize: "10",
      ordernar: sort,
    }),
  );

  return response.json();
}

export default function Playlists() {
  const [playlists, setPlaylists] = useState<PlaylistResponse>();
  const [loading, setLoading] = useState(false);
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const location = useLocation();
  const scrollTop = useScrollTop();

  useEffect(() => {
    try {
      setLoading(true);
      const queryLocation = new URLSearchParams(location.search);
      const search = {
        page: queryLocation.get("page") || "1",
        categoriaId: params?.id || undefined,
        titulo: queryLocation.get("f") || "",
        sort: queryLocation.get("s") || undefined,
      };
      getCategoriasPlaylists(search).then((data) => {
        setPlaylists(data);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  }, [params?.id, location.search]);

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <>
      <Loader show={loading} />
      <div
        style={{
          background: "linear-gradient(137.1deg, #107B95 6.6%, #1BB28E 98.07%)",
        }}
        className="flex h-32 w-full"
      >
        <Container className="py-9 ">
          <Breadcrumb className="text-start  font-normal text-white-200">
            <BreadcrumbList className="flex justify-start text-start">
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Home</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink href="/playlists">Playlists</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink href="/playlists">
                  Playlists Sugeridas
                </BreadcrumbLink>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <h2 className="leading-6.5 mt-3 text-1.5xl font-semibold normal-case text-white-200">
            {(playlists?.items?.length || 0) > 0
              ? playlists?.items[0]?.categoriaDescricao
              : ""}
          </h2>
        </Container>
      </div>
      <Container className="h-full min-h-screen py-10">
        <div className="flex flex-col gap-10">
          <Menu typeFiltro="PLAYLIST" qtdConteudos={playlists?.totalItems} />
          <div className="mt-20 md:mt-4">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
              {playlists?.items?.map((playlist: Playlist) => (
                <Card
                  className="cursor-pointer shadow-none"
                  key={playlist.id}
                  onClick={() => {
                    history.push(`/playlists/${playlist.id}/detalhe`);
                  }}
                >
                  <CardHeader className="relative z-10 h-[227px] grow rounded-xl border p-6">
                    <img
                      alt="imagem capa objeto educacional"
                      src={playlist?.thumbnail || CapaPlaylist}
                      width={300}
                      height={150}
                      className={
                        "absolute left-0 top-0 h-full w-full rounded-xl object-cover object-top "
                      }
                    />
                    <div className="absolute bottom-2 left-1/2 -z-10 flex h-full w-[calc(100%-6%)] -translate-x-1/2  transform rounded-xl border-[1px] border-[#F5F8FF] bg-[#E8E9EB]" />
                    <div className="absolute bottom-4  left-1/2 -z-20 flex h-full w-[calc(100%-12%)] -translate-x-1/2  transform rounded-xl border-[1px] border-[#F5F8FF] bg-[#E8E9EB]" />
                    <div
                      className={`absolute left-[24px] top-[17.78px] max-w-sm md:left-6 md:top-6 `}
                    >
                      <Rotulo rotuloName={"Playlist"} id={1} />
                    </div>
                    <Badge className="absolute bottom-[19.78px] right-6 border-0 border-none bg-black-900">
                      <span className="text-xxs font-bold text-white-200 md:text-xs">
                        {`${playlist.qtdVideos} Vídeos`}
                      </span>
                    </Badge>
                    <div className="absolute  left-1/2 top-1/2 flex h-[42px] w-[125px] -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-lg bg-black-900/95">
                      <span className="text-xs font-semibold text-white-200">
                        Reproduzir tudo
                      </span>
                    </div>
                    {playlist?.visualizado && (
                      <span className="absolute bottom-[19.78px] left-[19.78px] text-xxs font-bold text-white-200 md:bottom-6 md:left-6 md:text-xs">
                        Visualizado
                      </span>
                    )}
                  </CardHeader>
                  <CardContent className="mt-3 flex flex-col gap-[6px] p-0">
                    <span className="text-base font-semibold leading-5 text-[#111316]">
                      {playlist.titulo.length > 30
                        ? `${playlist.titulo.substring(0, 30)}...`
                        : playlist.titulo}
                    </span>
                    <span className="text-xxs font-semibold leading-4 text-[#626466]">
                      Confira a playlist completa
                    </span>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export function MoreOptions({ idOe }: { idOe?: number }) {
  const mostrarModalPlaylist = useModalPlaylist();

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button aria-haspopup="menu">
            <EllipsisVertical className="size-5" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem
            onClick={() => {
              mostrarModalPlaylist.mostrar({
                idOe: idOe!,
              });
            }}
            className="flex cursor-pointer gap-3 px-3 py-2 text-black-900 hover:bg-slate-50"
          >
            <Bookmark />
            Salvar na playlist
          </DropdownMenuItem>
          <DropdownMenuItem className="flex cursor-pointer gap-3 px-3 py-2 text-black-900 hover:bg-slate-50">
            <Send className="size-5" />
            Compartilhar
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
