import { Bookmark, Plus, Share2 } from "lucide-react";
import { Breadcrumb, BreadcrumbItem } from "../../../shared/components";
import Container from "../../../shared/components/Container";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../shared/components/ui/avatar";
import {
  BreadcrumbList,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from "../../../shared/components/ui/breadcrumb";
import { Button } from "../../../shared/components/ui/button";
import { ObjetoEducacionalEntity } from "../../../generated/graphql";
import { cn } from "../../../lib/utils";
import { ScrollArea } from "../../../shared/components/ui/scroll-area";
import { useContext, useEffect, useRef, useState } from "react";
import Tags from "../../../shared/components/Tags";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "../../../shared/components/ui/dialog";
import { Input } from "@material-ui/core";
import { useHistory } from "react-router";
import { UserContext } from "../../../context/UserContext";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import useOeConsume from "../../../hooks/useOeConsume";

interface OeTextoPageProps {
  oe: ObjetoEducacionalEntity;
}

export default function OeTextoPage(props: OeTextoPageProps) {
  const [limitTag, setLimitTag] = useState<boolean>(true);

  const consume = useOeConsume();
  const { user } = useContext(UserContext);
  const handleTimeUpdateRef = useRef<any>(null);

  useEffect(() => {
    const timeUpdateSubject = new Subject();
    // Atribui a função handleTimeUpdate ao useRef
    handleTimeUpdateRef.current = (event: any, objetoEducacional: any) => {
      timeUpdateSubject.next({ event, objetoEducacional });
    };
    // Aplica o debounceTime ao subject
    const debouncedTimeUpdate = timeUpdateSubject.pipe(debounceTime(500));
    // Assina o subject debounced
    const subscription = debouncedTimeUpdate.subscribe(async (data: any) => {
      // Obtém o progresso atual do vídeo
      const oeAssistido =
        user?.attributes?.OesConsumidosParcialmente?.data.find(
          (item) =>
            !!item?.attributes?.Oes_Assistidos &&
            item.attributes.Oes_Assistidos.data.find(i => i.id === props.oe.id) !== undefined
        );
      if ((oeAssistido?.attributes?.segundos || 0) > data.event.partialDuration) {
        return;
      }
      await consume.saveOeConsume({
        id: oeAssistido?.id?.toString() || undefined,
        partialDuration: data.event.partialDuration,
        totalDuration: data.event.totalDuration,
        oe: props.oe.id,
        user: user?.id,
      });
    });
    return () => {
      subscription.unsubscribe();
      timeUpdateSubject.complete();
    };
  }, [user])

  const handleScroll = () => {
    const scrollTop = window.scrollY; // Posição atual do scroll
    const windowHeight = window.innerHeight; // Altura visível da janela
    const fullHeight = document.documentElement.scrollHeight - 2500;
    const oeAssistido =
        user?.attributes?.OesConsumidosParcialmente?.data.find(
          (item) =>
            !!item?.attributes?.Oes_Assistidos &&
            item.attributes.Oes_Assistidos.data.find(i => i.id === props.oe.id) !== undefined
        );
      if ((oeAssistido?.attributes?.segundos || 0) > (scrollTop + windowHeight)) {
        return;
      }
    handleTimeUpdateRef.current({
      partialDuration: parseInt((scrollTop + windowHeight).toFixed(0)),
      totalDuration: parseInt(fullHeight.toFixed(0)),
    })
    // Verifica se o scroll chegou ao final da página
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const criarTituloSecaoTexto = (index: number) =>
    `Seção ${index + 1 <= 9 ? "0" + (index + 1) : index + 1}`;

  const tags = limitTag
    ? props.oe?.attributes?.palavras_chave?.data?.slice(0, 1)
    : props.oe?.attributes?.palavras_chave?.data;

  return (
    <>
      <Container className="max-w-[34.75rem]">
        <Breadcrumb className="text-start font-normal">
          <BreadcrumbList className="flex justify-start text-start">
            <BreadcrumbItem className="!text-black-900">
              <BreadcrumbLink href="/" className="!text-black-900">
                Home
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink
                className="!text-black-900"
                href="/continue-assistindo"
              >
                Pediatria{" "}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink
                className="!text-black-900"
                href="/continue-assistindo"
              >
                Segurança em Pediatria recém-nascido{" "}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <div className="flex flex-col gap-3 py-4">
          <h1 className="text-xxl font-semibold leading-7 text-[#107B95]">
            {props?.oe?.attributes?.Titulo}
          </h1>
          <p className="text-base font-normal leading-6 text-[#626466]">
            {props?.oe?.attributes?.Descricao?.replace(/<[^>]*>?/gm, "")}
          </p>
        </div>

        <Autores isFooter={false} />
        <div className="flex gap-2 py-6">
          <Button data-testid="button-compartilhar" variant="outline">
            <Share2 size={16} className="mr-2" />
            Compartilhar
          </Button>
          <Button data-testid="button-favoritar" variant="outline">
            <Bookmark size={16} className="mr-2" />
            Favoritar
          </Button>
        </div>
        <div className="flex flex-col gap-2 py-6">
          <span className="text-sm font-bold text-black-900">Recomendado:</span>
          <p className="text-sm font-normal">
            {props?.oe?.attributes?.PublicoAlvo?.data?.map(
              (publico: any, index: number) => (
                <>
                  {publico.attributes?.titulo}
                  {index <
                    (props?.oe?.attributes?.PublicoAlvo?.data?.length ?? 0) -
                    1 && ", "}
                </>
              ),
            )}
          </p>
        </div>

        {props?.oe?.attributes?.SecoesTexto?.map((text, index) => (
          <div key={text?.id}>
            <h2>{criarTituloSecaoTexto(index)}</h2>
            <div
              dangerouslySetInnerHTML={{ __html: text?.Secao }}
              className="text-base font-normal leading-6 text-[#626466]"
            />
          </div>
        ))}

        <div className="py-2">
          <span className="text-base font-medium italic leading-6 text-black-900">
            Fontes e citações nesse formato italico
          </span>
        </div>
        <div className="flex flex-col gap-1 py-6">
          <span className="text-sm font-bold text-black-900">
            Palavras-chave:
          </span>
          <div className="flex flex-wrap gap-[6px]">
            {tags?.map((m, index) => (
              <Tags
                key={index}
                isSearchable={true}
                title={m.attributes?.titulo!}
                className="bg-[#F5F8FF] [&>span]:text-[#004F92]"
              />
            ))}
          </div>
        </div>
        <div className=" flex h-auto flex-col items-center justify-center gap-3 border-b border-t py-14">
          {/* <div className="flex flex-row gap-2">
            <div className="flex">
              {props?.oe?.attributes?.Autores?.data?.map(
                (autor: any, index: number) => (
                  <>
                    <Avatar
                      className={cn(
                        "size-12 border-2 bg-slate-50",
                        index > 0 ? "-ml-3" : "",
                      )}
                    >
                      <AvatarImage
                        src={autor.attributes?.foto?.data?.attributes?.url}
                        alt={autor?.Nome}
                      />
                      <AvatarFallback>{autor?.Nome}</AvatarFallback>
                    </Avatar>
                  </>
                ),
              )}
            </div>
            <div className="flex items-center gap-2">
              {(props?.oe?.attributes?.Autores?.data?.length ?? 0) > 1 ? (
                <span className="cursor-pointer text-xs font-normal leading-18 text-[#111316] hover:underline">
                  {
                    props?.oe?.attributes?.Autores?.data[0]?.attributes
                      ?.nome_exibicao
                  }{" "}
                  e {(props?.oe?.attributes?.Autores?.data?.length ?? 0) - 1}{" "}
                  outros
                </span>
              ) : (
                <div className="flex flex-col">
                  <span className="cursor-pointer text-xs font-semibold leading-18 text-[#111316] hover:underline">
                    {
                      props?.oe?.attributes?.Autores?.data[0]?.attributes
                        ?.nome_exibicao
                    }
                  </span>
                  <span className="cursor-pointer text-xs font-normal leading-18 text-[#111316] hover:underline">
                    {props?.oe?.attributes?.Autores?.data[0]?.attributes?.cargo}
                  </span>
                </div>
              )}
            </div>
          </div> */}
          <Autores isFooter={true} />
        </div>
      </Container>
    </>
  );

  function Autores({ isFooter = false }: { isFooter?: boolean } = {}) {
    const history = useHistory();

    return (
      <>
        <div className=" flex h-auto flex-row gap-2">
          <div className="flex">
            {props?.oe?.attributes?.Autores?.data?.map(
              (autor: any, index: number) => (
                <>
                  <Avatar
                    className={cn(
                      "size-12 border-2 bg-slate-50",
                      index > 0 ? "-ml-3" : "",
                    )}
                  >
                    <AvatarImage
                      src={autor.attributes?.foto?.data?.attributes?.url}
                      alt={autor?.Nome}
                    />
                    <AvatarFallback>{autor?.Nome}</AvatarFallback>
                  </Avatar>
                </>
              ),
            )}
          </div>
          <div className="flex items-center gap-2">
            {(props?.oe?.attributes?.Autores?.data?.length ?? 0) > 1 ? (
              <Dialog>
                <DialogTrigger asChild>
                  <span className="cursor-pointer text-xs font-normal leading-18 text-[#111316] hover:underline">
                    {
                      props?.oe?.attributes?.Autores?.data[0]?.attributes
                        ?.nome_exibicao
                    }{" "}
                    e {(props?.oe?.attributes?.Autores?.data?.length ?? 0) - 1}{" "}
                    outros
                  </span>
                </DialogTrigger>
                <DialogContent className="max-w-sm">
                  <DialogHeader></DialogHeader>
                  <div className="flex flex-col gap-2">
                    {props?.oe?.attributes?.Autores?.data?.map(
                      (autor: any, index: number) => (
                        <div className="flex flex-row items-center gap-2">
                          <Avatar
                            className={cn("size-12 border-2 bg-slate-50")}
                          >
                            <AvatarImage
                              src={
                                autor.attributes?.foto?.data?.attributes?.url
                              }
                              alt={autor?.Nome}
                            />
                            <AvatarFallback>{autor?.Nome}</AvatarFallback>
                          </Avatar>
                          <div className="flex flex-col">
                            <span
                              onClick={() => {
                                history.push(`/autor/${autor.id}/detalhes`);
                              }}
                              className="cursor-pointer text-xs font-semibold leading-18 text-[#111316] hover:underline"
                            >
                              {
                                props?.oe?.attributes?.Autores?.data[0]
                                  ?.attributes?.nome_exibicao
                              }
                            </span>
                            <span className="text-xs font-normal leading-18 text-[#111316]">
                              {
                                props?.oe?.attributes?.Autores?.data[0]
                                  ?.attributes?.cargo
                              }
                            </span>
                          </div>
                        </div>
                      ),
                    )}
                  </div>
                  <DialogFooter className="flex w-full items-center justify-center">
                    <div className="flex w-full justify-center">
                      <Button
                        data-testid="button-seguir-todos"
                        type="submit"
                        className="self-center"
                      >
                        Seguir todos
                      </Button>
                    </div>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            ) : (
              <>
                <div className="flex flex-col">
                  <span className="cursor-pointer text-xs font-semibold leading-18 text-[#111316] hover:underline">
                    {
                      props?.oe?.attributes?.Autores?.data[0]?.attributes
                        ?.nome_exibicao
                    }
                  </span>
                  <span className="text-xs font-normal leading-18 text-[#111316]">
                    {props?.oe?.attributes?.Autores?.data[0]?.attributes?.cargo}
                  </span>
                  {!isFooter && (
                    <span className="text-xs font-normal leading-18 text-[#111316]">
                      {props?.oe?.attributes?.TempoMedioDuracao} min de leitura
                      -{" "}
                      {props.oe.attributes?.DataProducao
                        ? format(
                          new Date(
                            `${props.oe.attributes?.DataProducao}T00:00:00`,
                          ),
                          "dd/MM/yyyy",
                          { locale: ptBR },
                        )
                        : ""}
                    </span>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        {(props?.oe?.attributes?.Autores?.data?.length ?? 0) === 1 &&
          isFooter && (
            <div className="flex gap-2">
              <Button
                data-testid="button-compartilhar"
                variant="primary"
                className="flex items-center gap-2"
              >
                <Plus size={16} className="mr-1" />
                Seguir
              </Button>
              <Button
                data-testid="button-compartilhar"
                variant="outline"
                className="flex items-center gap-2"
              >
                <Plus size={16} className="mr-1" />
                Conteúdos
              </Button>
            </div>
          )}
      </>
    );
  }
}
