import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";


import NenhumItem from "../../assets/svgs/nenhum_item.svg";
import VerTudoIcon from "../../assets/svgs/icons/ver-tudo.svg";


import { getSearchQuery } from "../../helpers/common";

import { BannerSection, CardSection, HeaderSection, Loader } from "../../shared/components";
import OePesquisaCabecalho from "./components/index";

import { useQueryParams } from "../../hooks/useQuerystring";

import { UserContext } from "../../context/UserContext";

import "./styles.scss";
import { useScrollTop } from "../../shared/hooks/useScrollTop";

import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from "../../shared/components//ui/breadcrumb";

import { TIPO_AREA_PAGINA, TIPO_PAGINA } from "../../hooks/useBannerApi";
import Container from "../../shared/components/Container";
import { useAreaInteresseAreaSeach } from "../../hooks/useAreaInteresseSubSeach";
import {
  Banner,
  loadBanner,
} from "../../graphql/collections/queries/banner.query";

import Menu from "../../shared/components/menu";
import { Pagination } from "@mui/material";
import CardObjetoEducacional from "../../shared/components/oe-card";
import { ObjetoEducacional, Root, SubAreaInteresse } from "../../models/ObjetoEducacional";
import { fetchApi } from "../../shared/services/rest-dotnet.service";
import { Carousel, CarouselBullets, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "../../shared/components/ui/carousel";

interface SearchProps {
  p?: string;
  m?: string;
  t?: string;
  c?: string;
  s?: string;
  areaInteresseId: string;
}

async function searchObjetosEducacionais({
  p,
  m,
  t,
  c,
  s,
  areaInteresseId,
}: SearchProps): Promise<SubAreaInteresse[]> {
  const params = {
    profissoes: Array.isArray(p) ? p : p ? [p] : [],
    metodosDeEnsino: Array.isArray(m) ? m : m ? [m] : [],
    tipoMidia: Array.isArray(t) ? t : t ? [t] : [],
    possuiCertificado: c === "true",
    ordernar: s,
    areaInteresseId,
  };

  const response = await fetchApi(
    `sub-area-conhecimento/get-sub-area-conhecimento-by-id-area-conhecimento/${areaInteresseId}`,
    "POST",
    JSON.stringify(params),
  ).then((res) => {
    if (res) {
      return res.json();
    } else {
      return [] as SubAreaInteresse[];
    }
  });

  return response;
}


interface OePesquisaProps {
  location: any;
}

interface OePesquisaState {
  pagina: number;
  error: any;
  filters: any;
  search: string | null;
  sort: string;
}

export interface OeParametros {
  search?: string[];
  tiposOe?: string[];
  categorias?: string[];
  areaInteresse?: string[];
}

export interface SubArreasInteresseLocationParams {
  profissao?: boolean;
  publicoAlvo?: string;
  titulo?: string;
}

export const SubAreasInteresse: React.FC<OePesquisaProps> = (props) => {
  const [subAreas, setSubAreas] = useState<SubAreaInteresse[]>([]);
  const [searchParams, setSearchParams] = useState<SearchProps>(
    {} as SearchProps,
  );
  const scrollTop = useScrollTop();

  const location = useLocation();

  const params = useParams<{ name: string; id: string }>();

  const loadObjetos = async (search: SearchProps) => {
    setSubAreas(await searchObjetosEducacionais(search || searchParams));
  };

  useEffect(() => {
    const queryLocation = new URLSearchParams(location.search);
    const search = {
      m: queryLocation.get("m") || undefined,
      p: queryLocation.get("p") || undefined,
      t: queryLocation.get("t") || undefined,
      s: queryLocation.get("s") || undefined,
      c: queryLocation.get("c") || undefined,
      areaInteresseId: params.id,
    };
    setSearchParams(search);
    loadObjetos(search);
  }, [location.search]);

  scrollTop();

  const { user } = useContext(UserContext);

  const history = useHistory();

  const { state: areaInteresse } =
    useLocation<SubArreasInteresseLocationParams>();

  const [banners, setBanners] = useState<Banner[]>([]);

  const carregarBanner = async () => {
    const resultBanner = await loadBanner({
      homePage: false,
      areaDeConhecimentoId: [Number(params.id!)],
    });

    setBanners(resultBanner);
  };

  useEffect(() => {
    carregarBanner();
  }, [params.id]);


  const criarLink = (subAreaConhecimento: SubAreaInteresse) => {
    return `/area-de-interesse/${params
      ?.name!.toLowerCase()
      .replace(/[" "]/g, "-")}/${params.id
      }/${getNome(subAreaConhecimento)
        .toLowerCase()
        .replace(/[" "]/g, "-")}/${subAreaConhecimento?.id}`;
  };

  const getNome = (subAreaInteresse: SubAreaInteresse) => {
    if (subAreaInteresse.titulo.indexOf("/") > -1) {
      return subAreaInteresse.titulo.substring(
        subAreaInteresse.titulo.indexOf("/") + 1,
      );
    }
    return subAreaInteresse.titulo;
  };

  return (
    <>
      <HeaderSection classe="!px-0 py-9">
        <Breadcrumb className="flex justify-start !text-xxs font-normal text-white-200 md:text-xs">
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink tabIndex={0} className="hover:font-bold focus:font-bold" href="/">Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            {areaInteresse?.profissao ? (
              <BreadcrumbItem>
                <BreadcrumbLink tabIndex={0} className="hover:font-bold focus:font-bold" href="/profissoes">Profissões</BreadcrumbLink>
              </BreadcrumbItem>
            ) : (
              <BreadcrumbItem>
                <BreadcrumbLink tabIndex={0} className="hover:font-bold focus:font-bold" href="/areas-de-interesse">Áreas de interesse</BreadcrumbLink>
              </BreadcrumbItem>
            )}
            {areaInteresse?.profissao && (
              <BreadcrumbItem>
                <BreadcrumbLink tabIndex={0} className="hover:font-bold focus:font-bold" href="/profissoes">{areaInteresse.publicoAlvo}</BreadcrumbLink>
              </BreadcrumbItem>
            )}
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink tabIndex={-1}>{params.name &&
                params.name
                  .split("-")
                  ?.map((a) => a[0]?.toUpperCase() + a.substring(1))
                  ?.join(" ")}</BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <h2 className="flex flex-row leading-6.5 mt-3 text-base font-semibold normal-case text-white-200 md:text-1.5xl">
          {params.name &&
            params.name
              .split("-")
              ?.map((a) => a[0]?.toUpperCase() + a.substring(1))
              ?.join(" ")}
        </h2>
      </HeaderSection>
      <Container className="h-full min-h-screen  py-9 pb-9">
        <div className="flex flex-col items-center gap-10">
          <Menu qtdConteudos={subAreas.reduce((p, c) => (p || 0) + c.objetosEducacionais.length, 0)} typeFiltro="AREA_INTERESSE" />
          <div className="flex w-full flex-col justify-end gap-6 md:justify-center">
            {subAreas?.map((area, index) => (
              <React.Fragment key={index}>
                {area.objetosEducacionais && area.objetosEducacionais?.length > 0 && (
                  <div key={index} className="p-1">
                    <div className="flex items-center justify-between mb-3">
                      <h2 className="text-base font-semibold normal-case leading-18 text-brand-blue md:text-xxl md:leading-22 tablet:text-sm tablet:leading-[14px]">
                        {area.titulo}
                      </h2>
                      <span
                        className="flex cursor-pointer items-center gap-2 self-center text-xxs  font-semibold leading-[10px] text-[#626466] md:text-sm md:leading-18"
                        onClick={() => history.replace(criarLink(area))}
                      >
                        VER TUDO
                        <img
                          src={VerTudoIcon}
                          alt="icon-video"
                          width={24}
                          height={24}
                          className="h-[14.45px] w-[14.45px] md:h-6 md:w-6"
                        />
                      </span>
                    </div>
                    {area.objetosEducacionais && area.objetosEducacionais?.length > 0 ? (
                      <Carousel
                        opts={{
                          breakpoints: {
                            "(min-width: 768px)": { slidesToScroll: 3 },
                          },
                        }}
                        className="w-full"
                      >
                        <CarouselContent className="">
                          {area.objetosEducacionais?.map((_: ObjetoEducacional, index: number) => (
                            <CarouselItem
                              key={index}
                              className="overflow-hidden rounded-xl md:basis-1/2 lg:basis-1/3"
                            >
                              <CardObjetoEducacional objetoEducacional={_} />
                            </CarouselItem>
                          ))}
                        </CarouselContent>
                        <CarouselPrevious
                          data-testid="btn-previous-continue-assistindo"
                          height="h-[0px] md:h-[227.66px]"
                          className="hidden md:block"
                        />
                        <CarouselNext
                          data-testid="btn-next-continue-assistindo"
                          height="h-[0px] md:h-[227.66px]"
                          className="hidden md:block"
                        />
                        <CarouselBullets className="absolute left-0 right-0 mx-auto" />
                      </Carousel>
                    ) : (
                      <Carousel
                        opts={{
                          breakpoints: {
                            "(min-width: 768px)": { slidesToScroll: 3 },
                          },
                        }}
                        className="w-full"
                      >
                        <CarouselContent className="">
                          {Array.from({ length: 3 }).map((_, index) => (
                            <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3">
                              <div className="min-w-0 shrink-0 grow-0 basis-full overflow-hidden rounded-xl bg-slate-100 pl-4 md:basis-1/2 lg:basis-1/3">
                                <div className="flex h-[187.5px] w-full animate-[shimmer_2s_infinite] bg-gradient-to-r  from-transparent via-gray-400/20 to-transparent md:h-[227.66px]" />
                              </div>
                            </CarouselItem>
                          ))}
                        </CarouselContent>
                      </Carousel>
                    )}
                  </div>
                )}
                </React.Fragment>
            ))}
          </div>
          {subAreas && subAreas.length === 0 && (
            <div className="flex w-full max-w-[43.87rem] flex-col items-center justify-center text-center">
              <img src={NenhumItem} className="mb-6 h-52" />
              <span className="text-base font-semibold leading-18 text-[#004F92] md:text-xxl md:leading-26 tablet:text-xxl tablet:leading-26">
                Não encontramos conteúdos com as suas preferências
              </span>
              <span className="mt-3 text-sm font-medium leading-4 text-[#626466]  md:text-lg md:leading-22 tablet:text-lg tablet:leading-22 ">
                Parece que não encontramos conteúdos com os filtros que você
                escolheu. Tente ajustar ou selecionar novos filtros para ver
                mais opções!
              </span>
            </div>
          )}
        </div>
      </Container>

      {
        (() => {
          let bannersAcimaRodape = banners?.filter(
            (a) =>
              a.tipoPagina === TIPO_PAGINA.AREA_INTERESSE &&
              a.areaPagina === TIPO_AREA_PAGINA.ACIMA_RODAPE &&
              ((!!user?.id && a.ocultarLogado === false) || !user?.id),
          );

          return (
            <>
              {bannersAcimaRodape && bannersAcimaRodape.length ? (
                <div
                  data-banner
                  data-banner-rodape
                  className="flex w-[full] justify-center bg-gray-100 py-2"
                >
                  <Container>
                    <BannerSection items={bannersAcimaRodape} />
                  </Container>
                </div>
              ) : (
                ""
              )}
            </>
          );
        })()
      }
    </>
  );
};
