import { getUserContext } from "../context/UserContext";

const dataLayer = ((window as any)["dataLayer"] as any[]) || [];

export enum EDataLayerEventCategory {
  PAGE_VIEW = "pageview",
  OE_PAGEVIEW = "oe_pageview",
  ADE_CADASTRO = "ade_cadastro",
  ADE_MENU_HOME = "ade_menu_home",
  ADE_DESTAQUE = "ade_destaque",
  ADE_DESTAQUE_QUIZ = "ade_destaque_quiz",
  ADE_OUTRAS_AREAS_HOME = "ade_outrasareas_home",
  ADE_EVENTOS_CIENTIFICOS = "ade_eventos_cientificos",
  ADE_LOGIN = "ade_login",
  ADE_RODAPE_HOME = "ade_rodape_home",
  ADE_VIDEOS_HOME = "ade_videos_home",
  ADE_METODOS_ENSINO = "ade_metodos_ensino",
  ADE_CENTRAL_AUTORIA = "ade_central_autoria",
  ADE_OE_PAGINA = "ade_oe_pagina",
  ADE_ENSINO_EINSTEIN = "ade_ensino_einstein",
  ADE_OE_INTERACAO_AUDIO = "ade_oe_interação_audio",
  ADE_OE_INTERACAO_VIDEO = "ade_oe_interação_video",
  ADE_OE_DECLARACAO = "ade_oe_declaracao",
  ADE_OE_INTERACAO_TEXTO = "ade_oe_interação_texto",
  ADE_OE_INTERACAO_IMAGEM = "ade_oe_interacao_imagem",
  ADE_OE_RELEVANTE = "ade_oe_relevante",
  ADE_OE_FAVORITAR = "ade_oe_favoritar",
  ADE_OE_LEMBRAR = "ade_oe_lembrar",
  ADE_BANNER = "ade_banner",
  ADE_RECOMENDACAO = "ade_recomendacao_ensino",
  ADE_PESQUISA = "ade_pesquisa",
  ADE_ALTA_HOME = "ade_alta_home",
  ADE_AREAS_CONHECIMENTO = "ade_areas_conhecimento",
  ADE_PROFISSOES = "ade_profissoes",
  ADE_PROFISSOES_INTERESSE = "ade_profissoes_interesse",
  ADE_AREAS_INTERESSE = "ade_areas_interesse",
  ADE_PLAYLISTS = "ade_playlists",
  ADE_MEU_PERFIL = "ade_meu-perfil",
  ADE_MODAL_OPTIN = "ade_modal-optin",
  ADE_SPOTIFY_REDIRECT = "ade_spotify_redirect",
  ADE_MENU_PREPARA_EINSTEIN = "ade_menu_preparaeinstein",
  ADE_PREPARA_EINSTEIN_SAIBA_MAIS = "preparaeinstein_saibamais",
  ADE_PREPARA_EINSTEIN_VIEWS = "preparaeinstein_views",
  ADE_PREPARA_EINSTEIN_QUERO_COMECAR_INICIO = "preparaeinstein_querocomecar_inicio",
  ADE_PREPARA_EINSTEIN_QUERO_COMECAR_FIM = "preparaeinstein_querocomecar_fim",
  ADE_PREPARA_EINSTEIN_FORMULARIO_VIEWS = "preparaeinstein_formulario_views",
  ADE_PREPARA_EINSTEIN_FORMULARIO_ACESSAR = "preparaeinstein_formulario_acessar",
  ADE_PREPARA_EINSTEIN_CONTEUDO_VIEWS = "preparaeinstein_conteudo_views",
}

export enum EDataLayerEventAction {
  CLIQUE = "clique",
  NAVEGACAO = "navegação",
  PREECHIMENTO = "preenchimento",
}

export const useGtm = () => {
  const user = getUserContext();

  return {
    push(
      eventCategory: EDataLayerEventCategory,
      action: EDataLayerEventAction,
      extra: { [id: string]: any; label: string },
    ) {
      let _extra = {
        ...extra,
      };

      if (user) {
        _extra.usuario = user.id;
      }

      dataLayer.push({
        event: "gaevent",
        eventcategory: eventCategory,
        eventaction: action,
        ..._extra,
      });
    },
  };
};
